import { GetStaticPaths, GetStaticProps } from 'next';

import Page from '@/components/page';
import getPageStaticPaths from '@/lib/get-page-static-paths';
import getPageStaticProps from '@/lib/get-page-static-props';
import joinSlugs from '@/lib/join-slugs';
import contentService from '@/middleware/content-service';
import mapToPageEntry from '@/middleware/mappers/pages/page';
import mapToSoundpiecesPageEntry from '@/middleware/mappers/pages/soundpieces';
import { PageProps } from '@/types/views/generic';

export const getStaticProps: GetStaticProps<PageProps> = (ctx) => {
  const slugs = joinSlugs(ctx.params?.['page-slugs']);

  if (slugs === 'watch') {
    return {
      redirect: {
        destination: '/watch/videos',
        permanent: true,
      },
    };
  }

  if (slugs === 'audios') {
    return getPageStaticProps({
      fetchPageFn: contentService.getPage(mapToSoundpiecesPageEntry),
      getPageFullPath: () => '/audios',
      staticPagePath: 'audios',
    })(ctx);
  }

  return getPageStaticProps({
    fetchPageFn: contentService.getPage(mapToPageEntry),
    getPageFullPath: () => slugs ?? '/',
    slugsParamName: 'page-slugs',
  })(ctx);
};

export const getStaticPaths: GetStaticPaths = getPageStaticPaths;

export default Page;
